.payment-success-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f8f9fa;
  }
  
  .success-icon {
    margin: auto;
    padding: 3em;
    text-align: center;
    width: 200px;
    height: 200px;
  }
  
  .button-group {
    display: flex;
    justify-content: center;
    gap: 10px;
  }
  