/* src/components/PrivacyPolicy.css */
.privacy-policy-container {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
    font-family: Arial, sans-serif !important;
  }
  
  .privacy-policy-content h1,
  .privacy-policy-content h2 {
    color: #333;
    text-align: center;
    margin-bottom: 20px; /* Adds space below each title */
    margin-top: 20px; /* Adds space below each title */
    font-size: 24px;
  }
  
  .privacy-policy-content h1 {
    margin-bottom: 40px; /* Adds extra space below the main title */
    margin-top: 40px; /* Adds extra space below the main title */
  }
  
  .privacy-policy-content p,
  .privacy-policy-content ul {
    color: #666;
    line-height: 1.6;
    text-align: justify; /* Justifies the paragraph text */
  }
  
  .privacy-policy-content ul {
    list-style-type: disc;
    margin-left: 20px;
  }
  
  .privacy-policy-content > h2 + p {
    margin-top: 20px; /* Adds space between title and first paragraph */
  }
  