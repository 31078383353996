.card {
  margin-bottom: 1rem;
}

.card-header {
  background-color: #f8f9fa;
}

.card-body .card-img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.card-title {
  font-weight: bold;
}

.list-group-item {
  padding: 0.75rem 1.25rem;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  border-left: 0px solid transparent;
  border-right: 0px solid transparent;
}

.list-group-item:first-child {
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}

.list-group-item:last-child {
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}

.list-group-item strong {
  display: inline-block;
}
