.default-image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 300px; /* Adjust height as needed */
  }
  
  .default-image-container img {
    max-width: 100%;
    height: auto;
  }

  .content-container {
    font-family: Arial, sans-serif !important;
  }
  
  .content-container h1,
  .content-container h2 {
    color: #333;
    margin-bottom: 20px;
  }
  
  .content-container p {
    color: #666;
    line-height: 1.6;
  }
  
  .content-container .text-center {
    text-align: center;
  }
  
  .content-container .d-flex {
    display: flex;
    align-items: center;
  }

  .content-container img {
    width: 50% !important;
    object-fit: cover;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
  }