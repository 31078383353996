/* src/components/AboutUs.css */
.about-us-container {
    font-family: Arial, sans-serif !important;
  }
  
  .about-us-container h1,
  .about-us-container h2 {
    color: #333;
    margin-bottom: 20px;
  }
  
  .about-us-container p {
    color: #666;
    line-height: 1.6;
  }
  
  .about-us-container .text-center {
    text-align: center;
  }
  
  .about-us-container .d-flex {
    display: flex;
    align-items: center;
  }
  
  .about-us-container img {
    width: 100%;
    border-radius: 5px;
  }
  