.accordion-item {
    border: 1px solid #ccc !important;
    border-radius: 0 !important;
    box-shadow: none !important;
  }
  
  .accordion-header {
    border-bottom: 1px solid #ccc !important;
    border-radius: 0 !important;
    box-shadow: none !important;
  }
  
  .accordion-body {
    border-top: 1px solid #ccc !important;
    border-radius: 0 !important;
    box-shadow: none !important;
  }
  
  .accordion-button {
    border-radius: 0 !important;
    outline: none !important;
    box-shadow: none !important;
  }

  
  .card {
   
    --bs-card-border-width: 0px solid black !important;
    --bs-card-border-radius: 0px !important;
    --bs-card-box-shadow: none !important;
    --bs-card-inner-border-radius: 0px !important;
    --bs-card-cap-padding-y: 0.5rem;
    --bs-card-cap-padding-x: 1rem;
    --bs-card-cap-bg: rgba(var(--bs-body-color-rgb), 0.03);
    --bs-card-cap-color: ;
    --bs-card-height: ;
    --bs-card-color: ;
    --bs-card-bg: var(--bs-body-bg);
    --bs-card-img-overlay-padding: 1rem;
    --bs-card-group-margin: 0.75rem;
    background-clip: none !important;
    border: 0px solid transparent !important;
    border-radius: 0px !important;
  }